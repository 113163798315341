import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthoritativeRecordActionTypes,
  CreateAuthoritativeRecord,
  FetchAuthoritativeRecords,
  FetchNotificationsCount,
  ResetAuthoritativeRecordsFilter,
  UpdateAuthoritativeRecordDeactivated,
  UpdateAuthoritativeRecordEntityType,
  UpdateAuthoritativeRecordPagination,
  UpdateAuthoritativeRecords,
  UpdateAuthoritativeRecordSearchTerm,
} from 'src/app/store/actions';
import { Chips } from 'primeng/chips';
import { getAuthoritativeRecordsFilterState, getAuthoritativeRecordsState } from 'src/app/store';
import { LazyLoadEvent } from 'primeng/api';
import { FetchLowConfidenceScoresCount } from 'src/app/store/actions/low.confidence.score.actions';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { Actions, ofType } from '@ngrx/effects';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'app-authoritative-records',
  templateUrl: './authoritative.records.component.html',
  styleUrls: ['./authoritative.records.component.css'],
})
export class AuthoritativeRecordsComponent implements OnInit {
  @Input() searchtext: string = null;
  @Input() isInDialog: boolean = false;
  @Input() searchEntityType: string = null;

  @ViewChild('entityTypesMultiSelect') entityTypesMultiSelect: MultiSelect;
  @ViewChild('authoritativeRecordsActivatedStatesMultiSelect') authoritativeRecordsActivatedStatesMultiSelect: MultiSelect;
  @ViewChild('dt') dataTable: Table;

  records: any[] = [];
  selectedEntityTypes: any[] = [];
  selectedEntityType: any;
  selectedEntityTypeDropdown: any;
  totalRecordCount: number;
  isLoading: boolean = false;
  searchTerm: string;
  selected: any;
  selectionMode: string;
  selectedAuthoritativeRecords: any[] = [];
  first: any;
  rows: any;
  size: number = 50;
  sortField: string;
  messages: any[] = [];
  sortOrder: number;
  selectedTypes: any[];
  entityTypes: any[] = [];
  selectedActivatedStates: any[] = [];
  authoritativeRecordsActivatedStates: any[] = [];
  showCreateForm: boolean = false;
  formModel: any = {};
  isCreatingRecord: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private actions: Actions,
  ) {}

  ngOnInit(): void {
    this.selectionMode = this.isInDialog ? "single" : "multiple"
    this.selectedEntityTypeDropdown = 'os';

    this.store.select(getAuthoritativeRecordsState).subscribe((data) => {
      if (data) {
        this.authoritativeRecordsActivatedStates = data.activatedStates.map((activatedState: string) => { return { activatedState: activatedState }});
        this.records = data.authoritativeRecords;
        this.isLoading = data.isLoading;
        this.totalRecordCount = data.totalRecordCount
        this.entityTypes = data.entityTypes.map((entityType: string) => { return { entityType: entityType }});
      }
    })

    this.store.select(getAuthoritativeRecordsFilterState).subscribe((data) => {
      if (data) {
        this.selectedActivatedStates = data.selectedActivatedStates;
        this.selectedEntityTypes = data.entityType === '' ? null : data.entityType.split(',').map((entityType: string) => { return { entityType: entityType }});
        this.first = data.from;
        this.rows = data.size;
        this.sortField = data.sort.split(':')[0];
        this.sortOrder = data.sort.split(':')[1] === 'asc' ? 1 : -1;

        if (this.searchtext !== null && this.searchEntityType !== null) {
          this.searchTerm = this.searchtext;
          data.query = this.searchtext;
          data.entityType = this.searchEntityType;
          this.searchtext = null;
          this.searchEntityType = null;
        } else {
          this.searchTerm = data.query;
        }

        if (this.isInDialog) {
          data.deactivated = false
        }

        this.store.dispatch(new FetchAuthoritativeRecords(data));
        this.store.dispatch(new FetchLowConfidenceScoresCount());
        this.store.dispatch(new FetchNotificationsCount());
      }
    });
  }

  clearForm() {
    this.formModel = {}
  }

  hasLength(data: any) {
    if (data) {
      return data.length > 0;
    }
    return false;
  }

  activateSelectedAuthoritativeRecords() {
    this.store.dispatch(new UpdateAuthoritativeRecords({
      ids: this.selectedAuthoritativeRecords.map((record: any) => record.id),
      update: {
        deactivated: false,
        updatedBy: JSON.parse(localStorage.getItem('user')).displayName
      }
    }))

    // When deactivating authoritative records is successful
    this.actions
      .pipe(ofType(AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordsSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `${this.selectedAuthoritativeRecords.length} records have been activated successfully`);
        setTimeout(() => {
          // Refetch notifications every time the filter state changes
          this.store.select(getAuthoritativeRecordsFilterState).pipe(first()).toPromise().then((data) => {
            this.store.dispatch(new FetchAuthoritativeRecords(data));
            this.store.dispatch(new FetchNotificationsCount());
            this.store.dispatch(new FetchLowConfidenceScoresCount());
            this.selectedAuthoritativeRecords = [];
          });
        }, 2000);
      });
    
    // When deactivating authoritative records fails
    this.actions
      .pipe(ofType(AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordsFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', data.payload.message);
      });
  }

  deactivateSelectedAuthoritativeRecords() {
    this.store.dispatch(new UpdateAuthoritativeRecords({
      ids: this.selectedAuthoritativeRecords.map((record: any) => record.id),
      update: {
        deactivated: true,
        updatedBy: JSON.parse(localStorage.getItem('user')).displayName
      }
    }))

    // When deactivating authoritative records is successful
    this.actions
      .pipe(ofType(AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordsSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `${this.selectedAuthoritativeRecords.length} records have been deactivated successfully`);
        setTimeout(() => {
          // Refetch notifications every time the filter state changes
          this.store.select(getAuthoritativeRecordsFilterState).pipe(first()).toPromise().then((data) => {
            this.store.dispatch(new FetchAuthoritativeRecords(data));
            this.store.dispatch(new FetchNotificationsCount());
            this.store.dispatch(new FetchLowConfidenceScoresCount());
            this.selectedAuthoritativeRecords = [];
          });
        }, 2000);
      });
    
    // When deactivating authoritative records fails
    this.actions
      .pipe(ofType(AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordsFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', data.payload.message);
      });
  }

  applySearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.store.dispatch(new UpdateAuthoritativeRecordSearchTerm({ from: 0, size: this.size, query: searchTerm }));
  }

  applyTableFilters(event: LazyLoadEvent) {
    this.store.dispatch(new UpdateAuthoritativeRecordPagination({ from: event.first, size: event.rows, sort: event.sortField !== null ? `${event.sortField}:${event.sortOrder === -1 ? "desc" : "asc"}` : '' }));
  }

  onSubmit(formRefValue: any) {
    let updateBody: any = {
      alias: formRefValue.alias,
      akaName: formRefValue.akaName,
      akaVersion: formRefValue.akaVersion,
      baseReleaseDate: formRefValue.baseReleaseDate,
      category: formRefValue.category,
      categoryGroup: formRefValue.categoryGroup,
      description: formRefValue.description,
      endOfLifeDate: formRefValue.endOfLifeDate,
      endOfMaintenanceDate: formRefValue.endOfMaintenanceDate,
      endOfSupportDate: formRefValue.endOfSupportDate,
      entityType: this.selectedEntityTypeDropdown,
      extendedEndOfMaintenanceDate: formRefValue.extendedEndOfMaintenanceDate,
      extendedEndOfSupportDate: formRefValue.extendedEndOfSupportDate,
      family: formRefValue.family,
      licensing: formRefValue.licensing,
      licenseMetrics: formRefValue.licenseMetrics,
      licenseModel: formRefValue.licenseModel,
      name: formRefValue.name,
      partOfASuite: formRefValue.partOfASuite,
      parent: formRefValue.parent,
      releaseEndDate: formRefValue.releaseEndDate,
      releaseStartDate: formRefValue.releaseStartDate,
      softwareSuite: formRefValue.softwareSuite,
      source: 'manual',
      subCategory: formRefValue.subCategory,
      updatedBy: JSON.parse(localStorage.getItem('user')).displayName,
      vendor: formRefValue.vendor,
      version: formRefValue.version
    }

    this.store.dispatch(new CreateAuthoritativeRecord(updateBody));
    this.isCreatingRecord = true;

    // When updating authoritative records is successful
    this.actions
      .pipe(ofType(AuthoritativeRecordActionTypes.CreateAuthoritativeRecordSuccess))
      .subscribe((data: any) => {
        this.showCreateForm = false;
        this.formModel = {};
        this.isCreatingRecord = false;
        this.showMessage('success', 'Success', `Record has been created successfully`);
        setTimeout(() => {
          this.store.select(getAuthoritativeRecordsFilterState).pipe(take(1)).toPromise().then((data) => {
            this.store.dispatch(new FetchAuthoritativeRecords(data));
          });
        }, 2000);
      });

    // When updating authoritative records fails
    this.actions
      .pipe(ofType(AuthoritativeRecordActionTypes.CreateAuthoritativeRecordFailed))
      .subscribe((data: any) => {
        this.showCreateForm = false;
        this.formModel = {};
        this.isCreatingRecord = false;
        this.showMessage('error', 'Error', data.payload.message);
      });
  }

  setEntityTypes(filters: any[]) {
    this.selectedEntityTypes = filters;
  }

  resetFilters() {
    this.dataTable.reset();
    this.store.dispatch(new ResetAuthoritativeRecordsFilter());
  }

  applyEntityTypes() {
    this.store.dispatch(new UpdateAuthoritativeRecordEntityType({ entityType: this.selectedEntityTypes.map((filter: any) => filter.entityType).join(',') }));
    this.entityTypesMultiSelect.hide();
  }

  setActivatedStates(filters: any[]) {
    this.selectedActivatedStates = filters;
  }

  applyActivatedStates() {
    let activated_states = this.selectedActivatedStates.map((filter: any) => filter.activatedState)
    if ((activated_states.includes("Show Active") && activated_states.includes("Show Deactivated")) || !activated_states.includes("Show Active") && !activated_states.includes("Show Deactivated")) {
      this.store.dispatch(new UpdateAuthoritativeRecordDeactivated({ deactivated: null, selectedActivatedStates: this.selectedActivatedStates}));
    } else if (activated_states.includes("Show Active") && !activated_states.includes("Show Deactivated")) {
      this.store.dispatch(new UpdateAuthoritativeRecordDeactivated({ deactivated: "false", selectedActivatedStates: this.selectedActivatedStates}));
    } else if (!activated_states.includes("Show Active") && activated_states.includes("Show Deactivated")) {
      this.store.dispatch(new UpdateAuthoritativeRecordDeactivated({ deactivated: "true", selectedActivatedStates: this.selectedActivatedStates}));
    }
    this.authoritativeRecordsActivatedStatesMultiSelect.hide();
  }

  showAuthoritativeRecordDetails(record: any) {
    if (!this.isInDialog) {
      this.router.navigate([
        `/authoritativerecords/${record.id}`,
      ]);
    } else {
      if (
        this.selected &&
        this.selected.id === record.id
      ) {
        this.selected = undefined;
      } else {
        this.selected = record;
      }
    }
  }

  showMessage(severity: string, summary: string, detail: string) {
    this.messages = [
      {
        severity: severity,
        summary: summary,
        detail: detail,
      },
    ];
    this.resetMessages();
  }

  resetMessages() {
    setTimeout(() => {
      this.messages = [];
    }, 4000);
  }

}
