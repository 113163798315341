import { Action } from '@ngrx/store';

export enum AuthoritativeRecordActionTypes {
  FetchAuthoritativeRecords = '[Authoritative Records Page] Fetch Authoritative Records',
  FetchAuthoritativeRecordsSuccess = '[Authoritative Records Page] Fetch Authoritative Records Success',
  FetchAuthoritativeRecordsFailed = '[Authoritative Records Page] Fetch Authoritative Records Failed',

  UpdateAuthoritativeRecords = '[Authoritative Records Page] Update Authoritative Records',
  UpdateAuthoritativeRecordsSuccess = '[Authoritative Records Page] Update Authoritative Records Success',
  UpdateAuthoritativeRecordsFailed = '[Authoritative Records Page] Update Authoritative Records Failed',

  UpdateAuthoritativeRecordPagination = '[Authoritative Records Page] Update Authoritative Record Pagination',
  UpdateAuthoritativeRecordSearchTerm = '[Authoritative Records Page] Update Authoritative Record Search Term',
  UpdateAuthoritativeRecordEntityType = '[Authoritative Records Page] Update Authoritative Record Entity Type',
  
  CreateAuthoritativeRecord = '[Authoritative Record Details Page] Create Authoritative Record',
  CreateAuthoritativeRecordSuccess = '[Authoritative Record Details Page] Create Authoritative Record Success',
  CreateAuthoritativeRecordFailed = '[Authoritative Record Details Page] Create Authoritative Record Failed',

  ResetAuthoritativeRecordsFilter = '[Authoritative Records Page] Update Authoritative Record Filters',
  UpdateAuthoritativeRecordDeactivated = '[Authoritative Records Page] Update Authoritative Record Deactivated',
}

export class FetchAuthoritativeRecords implements Action {
  readonly type = AuthoritativeRecordActionTypes.FetchAuthoritativeRecords;
  constructor(public payload: any) { }
}

export class FetchAuthoritativeRecordsSuccess implements Action {
  readonly type = AuthoritativeRecordActionTypes.FetchAuthoritativeRecordsSuccess;
  constructor(public payload: any) { }
}

export class FetchAuthoritativeRecordsFailed implements Action {
  readonly type = AuthoritativeRecordActionTypes.FetchAuthoritativeRecordsFailed;
  constructor(public payload: any) { }
}

export class UpdateAuthoritativeRecords implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecords;
  constructor(public payload: any) { }
}

export class UpdateAuthoritativeRecordsSuccess implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordsSuccess;
  constructor(public payload: any) { }
}

export class UpdateAuthoritativeRecordsFailed implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordsFailed;
  constructor(public payload: any) { }
}

export class UpdateAuthoritativeRecordPagination implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordPagination;
  constructor(public payload: any) { }
}

export class UpdateAuthoritativeRecordSearchTerm implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordSearchTerm;
  constructor(public payload: any) { }
}

export class UpdateAuthoritativeRecordEntityType implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordEntityType;
  constructor(public payload: any) { }
}

export class ResetAuthoritativeRecordsFilter implements Action {
  readonly type = AuthoritativeRecordActionTypes.ResetAuthoritativeRecordsFilter;
  constructor() { }
}

export class UpdateAuthoritativeRecordDeactivated implements Action {
  readonly type = AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordDeactivated;
  constructor(public payload: any) { }
}

export class CreateAuthoritativeRecord implements Action {
  readonly type = AuthoritativeRecordActionTypes.CreateAuthoritativeRecord;
  constructor(public payload: any) { }
}

export class CreateAuthoritativeRecordSuccess implements Action {
  readonly type = AuthoritativeRecordActionTypes.CreateAuthoritativeRecordSuccess;
  constructor(public payload: any) { }
}

export class CreateAuthoritativeRecordFailed implements Action {
  readonly type = AuthoritativeRecordActionTypes.CreateAuthoritativeRecordFailed;
  constructor(public payload: any) { }
}

export type AuthoritativeRecordActions =
  | FetchAuthoritativeRecords
  | FetchAuthoritativeRecordsSuccess
  | FetchAuthoritativeRecordsFailed
  | UpdateAuthoritativeRecords
  | UpdateAuthoritativeRecordsSuccess
  | UpdateAuthoritativeRecordsFailed
  | UpdateAuthoritativeRecordPagination
  | UpdateAuthoritativeRecordSearchTerm
  | UpdateAuthoritativeRecordEntityType
  | ResetAuthoritativeRecordsFilter
  | UpdateAuthoritativeRecordDeactivated
  | CreateAuthoritativeRecord
  | CreateAuthoritativeRecordSuccess
  | CreateAuthoritativeRecordFailed;
