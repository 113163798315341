import { Injectable } from '@angular/core';
import { Actions, ofType, Effect, act } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthoritativeRecordsService } from 'src/app/services/authoritative.records.service';
import {
  FetchAuthoritativeRecords,
  FetchAuthoritativeRecordsSuccess,
  FetchAuthoritativeRecordsFailed,
  MoveInputRecord,
  MoveInputRecordSuccess,
  MoveInputRecordFailed,
  AuthoritativeRecordActionTypes,
  AuthoritativeRecordDetailsActionTypes,
  UpdateAuthoritativeRecordsSuccess,
  UpdateAuthoritativeRecordsFailed,
  CreateAuthoritativeRecordSuccess,
  CreateAuthoritativeRecordFailed,
} from '../actions';

@Injectable()
export class AuthoritativeRecordEffects {
  params: any;
  constructor(
    private actions$: Actions,
    private authoritativeRecordsService: AuthoritativeRecordsService,
  ) {}

  @Effect()
  fetchAuthoritativeRecords$ = this.actions$.pipe(
    ofType(AuthoritativeRecordActionTypes.FetchAuthoritativeRecords),
    switchMap((action: FetchAuthoritativeRecords) =>
      this.authoritativeRecordsService.fetchAuthoritativeRecords(action.payload).pipe(
        map((response: any) => new FetchAuthoritativeRecordsSuccess(response)),
        catchError((error) =>
          of(
            new FetchAuthoritativeRecordsFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  moveInputRecord$ = this.actions$.pipe(
    ofType(AuthoritativeRecordDetailsActionTypes.MoveInputRecord),
    switchMap((action: MoveInputRecord) =>
      this.authoritativeRecordsService.updateInputRecord(action.payload).pipe(
        map((response: any) => new MoveInputRecordSuccess(response)),
        catchError((error) =>
          of(
            new MoveInputRecordFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  updateAuthoritativeRecords$ = this.actions$.pipe(
    ofType(AuthoritativeRecordActionTypes.UpdateAuthoritativeRecords),
    switchMap((action: any) =>
      this.authoritativeRecordsService.updateAuthoritativeRecords(action.payload).pipe(
        map((response: any) => new UpdateAuthoritativeRecordsSuccess(response)),
        catchError((error) =>
          of(
            new UpdateAuthoritativeRecordsFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  createAuthoritativeRecord$ = this.actions$.pipe(
    ofType(AuthoritativeRecordActionTypes.CreateAuthoritativeRecord),
    switchMap((action: any) =>
      this.authoritativeRecordsService.createAuthoritativeRecord(action.payload).pipe(
        map((response: any) => new CreateAuthoritativeRecordSuccess(response)),
        catchError((error) =>
          of(
            new CreateAuthoritativeRecordFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );
}
