import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmationService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from "primeng/calendar";

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ErrorDialogComponent } from '../components/error.dialog/error.dialog.component';
import { PrettyPrintPipe } from '../pretty-print.pipe';
import { AuthoritativeRecordsComponent } from '../components/authoritative.records/authoritative.records.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../../assets/i18n', '.json');
}

@NgModule({
  declarations: [
    ErrorDialogComponent, 
    PrettyPrintPipe,
    AuthoritativeRecordsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ChipsModule,
    ProgressSpinnerModule,
    PaginatorModule,
    CardModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    TabMenuModule,
    MultiSelectModule,
    CalendarModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    TableModule,
    ButtonModule,
  ],
  exports: [
    TranslateModule, 
    ErrorDialogComponent, 
    PrettyPrintPipe,
    AuthoritativeRecordsComponent
  ],
  providers: [ConfirmationService, MessageService],
})
export class AppSharedModule {}
