import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ChipsModule } from 'primeng/chips';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

// NgRx related imports
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { effects } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// application related imports
import { AppComponent } from './app.component';
import { app_environment } from 'src/app.environment';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';

// Module imports
import { DashboardModule } from './components/authoritative.records/authoritative.records.module';
import { LowConfidenceScoreModule } from './components/low.confidence.score/low.confidence.score.module';
import { SuggestionsModule } from './components/suggestions/suggestions.module';
import { LayoutModule } from './components/layout/layout.module';
import { ImportExportModule } from './components/import.export/import.export.module';
import { SynchronizeModule } from './components/synchronize/synchronize.module';

import { AppSharedModule } from './app-shared/app-shared.module';
// components
import { LoginComponent } from './components/login/login.component';

// PrimeNg related imports
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { ErrorResponseInterceptor } from './interceptors/error.response.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    ChipsModule,
    ButtonModule,
    BrowserAnimationsModule,
    AngularFirestoreModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(effects),
    AngularFireAuthModule,
    AngularFireModule.initializeApp(app_environment.firebaseConfig),
    TabMenuModule,
    TooltipModule,
    MessagesModule,
    MessageModule,
    !app_environment.production ? StoreDevtoolsModule.instrument() : [],
    DashboardModule,
    LowConfidenceScoreModule,
    ImportExportModule,
    SynchronizeModule,
    CardModule,
    LayoutModule,
    SuggestionsModule,
    AppSharedModule,
    HttpClientModule,
    AppRoutingModule, // place app routing module after eagerly loading modules
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
